var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label:
                    "Il PM verificherà la conformità del codice prodotto ai requisiti di sicurezza avvalendosi del prodotto/tool:",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.PMVerificaProgettoApplicativo,
                  callback: function ($$v) {
                    _vm.PMVerificaProgettoApplicativo = $$v
                  },
                  expression: "PMVerificaProgettoApplicativo",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _vm.PMVerificaProgettoApplicativo == "Altro"
                ? _c("v-text-field", {
                    attrs: {
                      label: "Specificare:",
                      "hide-details": "",
                      readonly: "",
                    },
                    model: {
                      value: _vm.AltroPMVerificaProgettoApplicativo,
                      callback: function ($$v) {
                        _vm.AltroPMVerificaProgettoApplicativo = $$v
                      },
                      expression: "AltroPMVerificaProgettoApplicativo",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  label:
                    "Il PM provvederà alla redazione della Relazione Tecnica, assicurando la registrazione dei fatti salienti\n     accaduti nel corso dello svolgimento della Commessa/Progetto Interno fino alla sua conclusione.",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.PMRedazioneProgettoApllicativo,
                  callback: function ($$v) {
                    _vm.PMRedazioneProgettoApllicativo = $$v
                  },
                  expression: "PMRedazioneProgettoApllicativo",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label:
                    "Il PM provvederà a richiedere ad HD la creazione di un progetto all’interno dello strumento:",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.PMStrumentoProgettoApplicativo,
                  callback: function ($$v) {
                    _vm.PMStrumentoProgettoApplicativo = $$v
                  },
                  expression: "PMStrumentoProgettoApplicativo",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _vm.PMStrumentoProgettoApplicativo
                ? _c("v-text-field", {
                    attrs: { "hide-details": "", readonly: "" },
                    model: {
                      value: _vm.PMStrumentoProgettoApplicativoTesto,
                      callback: function ($$v) {
                        _vm.PMStrumentoProgettoApplicativoTesto = $$v
                      },
                      expression: "PMStrumentoProgettoApplicativoTesto",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label:
                    "Il PM provvederà alla creazione di un Team, all’interno dello strumento:",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.TeamPMStrumentoProgettoApplicativo,
                  callback: function ($$v) {
                    _vm.TeamPMStrumentoProgettoApplicativo = $$v
                  },
                  expression: "TeamPMStrumentoProgettoApplicativo",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _vm.TeamPMStrumentoProgettoApplicativo
                ? _c("v-text-field", {
                    attrs: { "hide-details": "", readonly: "" },
                    model: {
                      value: _vm.TeamPMStrumentoProgettoApplicativoTesto,
                      callback: function ($$v) {
                        _vm.TeamPMStrumentoProgettoApplicativoTesto = $$v
                      },
                      expression: "TeamPMStrumentoProgettoApplicativoTesto",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  label:
                    "Il PM provvederà a comunicare formalmente ad HD tutti gli oggetti da inserire nei processi di backup  \n     necessari alla corretta archiviazione e salvaguardia in caso di eventuale necessità di ripristino o disastro,\n     rispondendo dei risultati.",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.PMComunicazioneProgettoApllicativo,
                  callback: function ($$v) {
                    _vm.PMComunicazioneProgettoApllicativo = $$v
                  },
                  expression: "PMComunicazioneProgettoApllicativo",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  label:
                    "Il PM segnalerà al diretto responsabile qualsiasi eventuale criticità riguardante il progetto, in particolare\n     per quanto concerne i non auspicabili disallineamenti rispetto alla pianificazione.",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.PMSegnalazioneProgettoApllicativo,
                  callback: function ($$v) {
                    _vm.PMSegnalazioneProgettoApllicativo = $$v
                  },
                  expression: "PMSegnalazioneProgettoApllicativo",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-textarea", {
                attrs: {
                  rows: "2",
                  label: "Note",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.NoteProgettoApplicativoTesto,
                  callback: function ($$v) {
                    _vm.NoteProgettoApplicativoTesto = $$v
                  },
                  expression: "NoteProgettoApplicativoTesto",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }