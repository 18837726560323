var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label:
                    "Il PM provvederà alla redazione della Relazione Tecnica, assicurando la registrazione dei fatti salienti\n     accaduti nel corso dello svolgimento della Commessa/Progetto Interno fino alla sua conclusione.",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.PMCriticita,
                  callback: function ($$v) {
                    _vm.PMCriticita = $$v
                  },
                  expression: "PMCriticita",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label:
                    "Il PM provvederà a comunicare formalmente ad HD tutti gli oggetti da inserire nei processi di backup  \n     necessari alla corretta archiviazione e salvaguardia in caso di eventuale necessità di ripristino o disastro,\n     rispondendo dei risultati.",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.PMBackup,
                  callback: function ($$v) {
                    _vm.PMBackup = $$v
                  },
                  expression: "PMBackup",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label:
                    "Il PM segnalerà al diretto responsabile qualsiasi eventuale criticità riguardante il progetto, in particolare\n     per quanto concerne i non auspicabili disallineamenti rispetto alla pianificazione.",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.PMSegnalazione,
                  callback: function ($$v) {
                    _vm.PMSegnalazione = $$v
                  },
                  expression: "PMSegnalazione",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-textarea", {
                attrs: {
                  rows: "2",
                  label: "Note",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.NoteProgettoInfrastruttura,
                  callback: function ($$v) {
                    _vm.NoteProgettoInfrastruttura = $$v
                  },
                  expression: "NoteProgettoInfrastruttura",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }