var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    [
      _c("v-expansion-panel-header", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var open = ref.open
              return [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "text--secondary", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-fade-transition",
                          { attrs: { "leave-absolute": "" } },
                          [
                            open
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          absolute: "",
                                          dark: "",
                                          fab: "",
                                          center: "",
                                          right: "",
                                          color: "pink",
                                        },
                                        on: {
                                          click: _vm.removeElementiIngresso,
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-minus")])],
                                      1
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        "Gli elementi in ingresso per il progetto sono i seguenti"
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-row",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { "no-gutters": "" },
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(
                                        "Nome Documento: " +
                                          _vm._s(
                                            _vm.NomeDocumento || "Non impostato"
                                          )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-expansion-panel-content",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Nome Doucmento",
                      rules: [
                        function (v) {
                          return !!v || "Item is required"
                        },
                      ],
                    },
                    model: {
                      value: _vm.NomeDocumento,
                      callback: function ($$v) {
                        _vm.NomeDocumento = $$v
                      },
                      expression: "NomeDocumento",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }