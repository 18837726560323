import { render, staticRenderFns } from "./PgaVerbaleStartupSubHeaderCreate.vue?vue&type=template&id=5d3b5a1d&"
import script from "./PgaVerbaleStartupSubHeaderCreate.vue?vue&type=script&lang=ts&"
export * from "./PgaVerbaleStartupSubHeaderCreate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VContainer,VRow,VSwitch,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d3b5a1d')) {
      api.createRecord('5d3b5a1d', component.options)
    } else {
      api.reload('5d3b5a1d', component.options)
    }
    module.hot.accept("./PgaVerbaleStartupSubHeaderCreate.vue?vue&type=template&id=5d3b5a1d&", function () {
      api.rerender('5d3b5a1d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/feature/Offerte/VerbaleStartup/Components/PgaVerbaleStartupSubHeaderCreate.vue"
export default component.exports