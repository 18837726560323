import { render, staticRenderFns } from "./PgaVerbaleStartupGovernanceProgettoApplicativoCreate.vue?vue&type=template&id=024b72f4&"
import script from "./PgaVerbaleStartupGovernanceProgettoApplicativoCreate.vue?vue&type=script&lang=ts&"
export * from "./PgaVerbaleStartupGovernanceProgettoApplicativoCreate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VChip,VCol,VContainer,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VRadio,VRadioGroup,VRow,VSwitch,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('024b72f4')) {
      api.createRecord('024b72f4', component.options)
    } else {
      api.reload('024b72f4', component.options)
    }
    module.hot.accept("./PgaVerbaleStartupGovernanceProgettoApplicativoCreate.vue?vue&type=template&id=024b72f4&", function () {
      api.rerender('024b72f4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/feature/Offerte/VerbaleStartup/Components/PgaVerbaleStartupGovernanceProgettoApplicativoCreate.vue"
export default component.exports