var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c(
                "v-radio-group",
                {
                  attrs: { label: "Tipologia Report" },
                  model: {
                    value: _vm.tipologiaReport,
                    callback: function ($$v) {
                      _vm.tipologiaReport = $$v
                    },
                    expression: "tipologiaReport",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { label: "Report Proge", value: "Proge" },
                  }),
                  _c("v-radio", { attrs: { label: "Altro", value: "Altro" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.checkDropDown(_vm.tipologiaReport),
                    expression: "checkDropDown(tipologiaReport)",
                  },
                ],
                attrs: { label: "Altro: ", "hide-details": "" },
                model: {
                  value: _vm.altroTipologiaReport,
                  callback: function ($$v) {
                    _vm.altroTipologiaReport = $$v
                  },
                  expression: "altroTipologiaReport",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.periodoReportItems,
                  label: "Periodo Report",
                  required: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.periodoReport,
                  callback: function ($$v) {
                    _vm.periodoReport = $$v
                  },
                  expression: "periodoReport",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.checkDropDown(_vm.periodoReport),
                    expression: "checkDropDown(periodoReport)",
                  },
                ],
                attrs: { label: "Altro: ", "hide-details": "" },
                model: {
                  value: _vm.altroPeriodoReport,
                  callback: function ($$v) {
                    _vm.altroPeriodoReport = $$v
                  },
                  expression: "altroPeriodoReport",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }