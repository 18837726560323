var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Il PM ha condiviso il Gantt di progetto con il Team.",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.PMGantt,
                  callback: function ($$v) {
                    _vm.PMGantt = $$v
                  },
                  expression: "PMGantt",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label:
                    "È stata condivisa la Tabella di Mapping delle Competenze con il Team, che riporta gli obiettivi di\n     arricchimento del know how di ognuno. La Tabella sarà aggiornata con cadenza settimanale, rispettando\n     le regole definite in procedura.",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.TabellaMapping,
                  callback: function ($$v) {
                    _vm.TabellaMapping = $$v
                  },
                  expression: "TabellaMapping",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label:
                    "La struttura del documento di Architettura sarà conforme a:",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.Standard,
                  callback: function ($$v) {
                    _vm.Standard = $$v
                  },
                  expression: "Standard",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.checkGovernance(_vm.Standard),
                    expression: "checkGovernance(Standard)",
                  },
                ],
                attrs: {
                  label: "Nome Documento:",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.AltroStandard,
                  callback: function ($$v) {
                    _vm.AltroStandard = $$v
                  },
                  expression: "AltroStandard",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  label:
                    "Come si evince dal Gantt, il documento di Architettura sarà completato entro il:",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.DocumentoArchitettura,
                  callback: function ($$v) {
                    _vm.DocumentoArchitettura = $$v
                  },
                  expression: "DocumentoArchitettura",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "2" } },
            [
              _vm.DocumentoArchitettura
                ? _c("v-text-field", {
                    attrs: { type: "date", "hide-details": "", readonly: "" },
                    model: {
                      value: _vm.GiornoDocumentoArchitettura,
                      callback: function ($$v) {
                        _vm.GiornoDocumentoArchitettura = $$v
                      },
                      expression: "GiornoDocumentoArchitettura",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "1" } },
            [
              _c("v-text-field", {
                attrs: { label: "Altro", "hide-details": "", readonly: "" },
                model: {
                  value: _vm.AltroProgettoInfrastruttura,
                  callback: function ($$v) {
                    _vm.AltroProgettoInfrastruttura = $$v
                  },
                  expression: "AltroProgettoInfrastruttura",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.AltroProgettoInfrastruttura,
                    expression: "AltroProgettoInfrastruttura",
                  },
                ],
                attrs: {
                  label: "Specificare:",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.TestoAltroProgettoInfrastruttura,
                  callback: function ($$v) {
                    _vm.TestoAltroProgettoInfrastruttura = $$v
                  },
                  expression: "TestoAltroProgettoInfrastruttura",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }