var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    [
      _c(
        "v-card",
        { staticClass: "mb-12" },
        [
          _c("v-card-title", [_vm._v(" Intestazione "), _c("v-spacer")], 1),
          _c("pga-verbale-startup-header-details", {
            model: {
              value: _vm.PgaVerbaleStartupHeaderDetails,
              callback: function ($$v) {
                _vm.PgaVerbaleStartupHeaderDetails = $$v
              },
              expression: "PgaVerbaleStartupHeaderDetails",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-12" },
        [
          _c(
            "v-card-title",
            [_vm._v(" Caratteristiche Generali "), _c("v-spacer")],
            1
          ),
          _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
            _vm._v("Piattaforma Tecnologica di Riferimento"),
          ]),
          _c("pga-verbale-startup-sub-header-details", {
            model: {
              value: _vm.PgaVerbaleStartupSubHeaderDetails,
              callback: function ($$v) {
                _vm.PgaVerbaleStartupSubHeaderDetails = $$v
              },
              expression: "PgaVerbaleStartupSubHeaderDetails",
            },
          }),
          _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
            _vm._v("Deliverables"),
          ]),
          _c("pga-verbale-startup-deliverables-details", {
            model: {
              value: _vm.PgaVerbaleStartupDeliverablesDetails,
              callback: function ($$v) {
                _vm.PgaVerbaleStartupDeliverablesDetails = $$v
              },
              expression: "PgaVerbaleStartupDeliverablesDetails",
            },
          }),
          _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
            _vm._v("Referenti Tecnici del Cliente"),
          ]),
          _c("pga-verbale-startup-referente-cliente-details", {
            model: {
              value: _vm.PgaVerbaleStartupReferenteClienteDetails,
              callback: function ($$v) {
                _vm.PgaVerbaleStartupReferenteClienteDetails = $$v
              },
              expression: "PgaVerbaleStartupReferenteClienteDetails",
            },
          }),
          _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
            _vm._v("Adempimenti del Team"),
          ]),
          _c("pga-verbale-startup-adempimenti-team-header-details", {
            attrs: { "tipologia-verbale": _vm.LabelAdempimentiTeam },
            model: {
              value: _vm.PgaVerbaleStartupAdempimentiTeamHeaderDetails,
              callback: function ($$v) {
                _vm.PgaVerbaleStartupAdempimentiTeamHeaderDetails = $$v
              },
              expression: "PgaVerbaleStartupAdempimentiTeamHeaderDetails",
            },
          }),
          _c("pga-verbale-startup-adempimenti-team-consultant-details", {
            model: {
              value: _vm.PgaVerbaleStartupAdempimentiTeamConsultantDetails,
              callback: function ($$v) {
                _vm.PgaVerbaleStartupAdempimentiTeamConsultantDetails = $$v
              },
              expression: "PgaVerbaleStartupAdempimentiTeamConsultantDetails",
            },
          }),
          _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
            _vm._v("Modalità e periodicità di Reporting"),
          ]),
          _c("pga-verbale-startup-modalita-reporting-details", {
            model: {
              value: _vm.PgaVerbaleStartupModalitaReportingDetails,
              callback: function ($$v) {
                _vm.PgaVerbaleStartupModalitaReportingDetails = $$v
              },
              expression: "PgaVerbaleStartupModalitaReportingDetails",
            },
          }),
        ],
        1
      ),
      _vm.TipologiaVerbaleStepper == "Progetto Applicativo"
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [_vm._v(" Progetto Applicativo "), _c("v-spacer")],
                1
              ),
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v("Elementi in Ingresso"),
              ]),
              _c("pga-verbale-startup-elementi-ingresso-details", {
                model: {
                  value: _vm.PgaVerbaleStartupElementiIngressoDetails,
                  callback: function ($$v) {
                    _vm.PgaVerbaleStartupElementiIngressoDetails = $$v
                  },
                  expression: "PgaVerbaleStartupElementiIngressoDetails",
                },
              }),
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v(
                  "La Governance del progetto prevede i seguenti adempimenti:"
                ),
              ]),
              _c(
                "pga-verbale-startup-governance-progetto-applicativo-details",
                {
                  model: {
                    value:
                      _vm.PgaVerbaleStartupGovernanceProgettoApplicativoDetails,
                    callback: function ($$v) {
                      _vm.PgaVerbaleStartupGovernanceProgettoApplicativoDetails =
                        $$v
                    },
                    expression:
                      "PgaVerbaleStartupGovernanceProgettoApplicativoDetails",
                  },
                }
              ),
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v("Altre informazioni Progetto Applicativo"),
              ]),
              _c(
                "pga-verbale-startup-altre-info-progetto-applicativo-details",
                {
                  model: {
                    value:
                      _vm.PgaVerbaleStartupAltreInfoProgettoApplicativoDetails,
                    callback: function ($$v) {
                      _vm.PgaVerbaleStartupAltreInfoProgettoApplicativoDetails =
                        $$v
                    },
                    expression:
                      "PgaVerbaleStartupAltreInfoProgettoApplicativoDetails",
                  },
                }
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.TipologiaVerbaleStepper == "Servizio di Manutenzione"
        ? _c(
            "v-card",
            [
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v(
                  "La Governance del progetto prevede i seguenti adempimenti:"
                ),
              ]),
              _c(
                "pga-verbale-startup-governance-servizio-manutenzione-details",
                {
                  model: {
                    value:
                      _vm.PgaVerbaleStartupGovernanceServizioManutenzioneDetails,
                    callback: function ($$v) {
                      _vm.PgaVerbaleStartupGovernanceServizioManutenzioneDetails =
                        $$v
                    },
                    expression:
                      "PgaVerbaleStartupGovernanceServizioManutenzioneDetails",
                  },
                }
              ),
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v("Altre informazioni Servizio di Manutenzione"),
              ]),
              _c(
                "pga-verbale-startup-altre-info-servizio-manutenzione-details",
                {
                  model: {
                    value:
                      _vm.PgaVerbaleStartupAltreInfoServizioManutenzioneDetails,
                    callback: function ($$v) {
                      _vm.PgaVerbaleStartupAltreInfoServizioManutenzioneDetails =
                        $$v
                    },
                    expression:
                      "PgaVerbaleStartupAltreInfoServizioManutenzioneDetails",
                  },
                }
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.TipologiaVerbaleStepper == "Progetto Infrastruttura"
        ? _c(
            "v-card",
            [
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v("Elementi in Ingresso"),
              ]),
              _c("pga-verbale-startup-elementi-ingresso-details", {
                model: {
                  value: _vm.PgaVerbaleStartupElementiIngressoDetails,
                  callback: function ($$v) {
                    _vm.PgaVerbaleStartupElementiIngressoDetails = $$v
                  },
                  expression: "PgaVerbaleStartupElementiIngressoDetails",
                },
              }),
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v(
                  "La Governance del progetto prevede i seguenti adempimenti:"
                ),
              ]),
              _c(
                "pga-verbale-startup-governance-progetto-infrastruttura-details",
                {
                  model: {
                    value:
                      _vm.PgaVerbaleStartupGovernanceProgettoInfrastrutturaDetails,
                    callback: function ($$v) {
                      _vm.PgaVerbaleStartupGovernanceProgettoInfrastrutturaDetails =
                        $$v
                    },
                    expression:
                      "PgaVerbaleStartupGovernanceProgettoInfrastrutturaDetails",
                  },
                }
              ),
              _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                _vm._v("Altre informazioni Progetto Infrastruttura"),
              ]),
              _c(
                "pga-verbale-startup-altre-info-progetto-infrastruttura-details",
                {
                  model: {
                    value:
                      _vm.PgaVerbaleStartupAltreInfoProgettoInfrastrutturaDetails,
                    callback: function ($$v) {
                      _vm.PgaVerbaleStartupAltreInfoProgettoInfrastrutturaDetails =
                        $$v
                    },
                    expression:
                      "PgaVerbaleStartupAltreInfoProgettoInfrastrutturaDetails",
                  },
                }
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }