var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Nome Cliente \n(o Titolo Progetto Interno)",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.nomeCliente,
                  callback: function ($$v) {
                    _vm.nomeCliente = $$v
                  },
                  expression: "nomeCliente",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Sigla Mnemonica",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.siglaMnemonica,
                  callback: function ($$v) {
                    _vm.siglaMnemonica = $$v
                  },
                  expression: "siglaMnemonica",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Nominativo PM:",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.nominativoPM,
                  callback: function ($$v) {
                    _vm.nominativoPM = $$v
                  },
                  expression: "nominativoPM",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-textarea", {
                attrs: {
                  rows: "1",
                  label: "Descrizione Commessa \n(o Progetto Interno)",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.descrizione,
                  callback: function ($$v) {
                    _vm.descrizione = $$v
                  },
                  expression: "descrizione",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Data:",
                  type: "date",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.data,
                  callback: function ($$v) {
                    _vm.data = $$v
                  },
                  expression: "data",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Tipologia Verbale",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.tipologiaVerbale,
                  callback: function ($$v) {
                    _vm.tipologiaVerbale = $$v
                  },
                  expression: "tipologiaVerbale",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }