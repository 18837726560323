var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label:
                    "Il PM ha condiviso con il Team il Documento di Procedure Operative denominato:",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.PMProcedure,
                  callback: function ($$v) {
                    _vm.PMProcedure = $$v
                  },
                  expression: "PMProcedure",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _vm.PMProcedure
                ? _c("v-text-field", {
                    attrs: {
                      label: "Nome Documento:",
                      "hide-details": "",
                      readonly: "",
                    },
                    model: {
                      value: _vm.DocumentoPMProcedure,
                      callback: function ($$v) {
                        _vm.DocumentoPMProcedure = $$v
                      },
                      expression: "DocumentoPMProcedure",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  "hide-details": "",
                  readonly: "",
                  label:
                    "È stata condivisa la Tabella di Mapping delle Competenze con il Team, che riporta gli obiettivi di\n       arricchimento del know how di ognuno. La Tabella sarà aggiornata con cadenza settimanale, rispettando\n       le regole definite in procedura.\n",
                },
                model: {
                  value: _vm.MappingManutenzione,
                  callback: function ($$v) {
                    _vm.MappingManutenzione = $$v
                  },
                  expression: "MappingManutenzione",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label:
                    "Sarà utilizzato il Tool di Trouble Ticketing denominato:",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.TroubleTicketing,
                  callback: function ($$v) {
                    _vm.TroubleTicketing = $$v
                  },
                  expression: "TroubleTicketing",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _vm.TroubleTicketing
                ? _c("v-text-field", {
                    attrs: {
                      label: "Nome Tool:",
                      "hide-details": "",
                      readonly: "",
                    },
                    model: {
                      value: _vm.ToolTroubleTicketing,
                      callback: function ($$v) {
                        _vm.ToolTroubleTicketing = $$v
                      },
                      expression: "ToolTroubleTicketing",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-text-field", {
                attrs: { label: "Altro", "hide-details": "", readonly: "" },
                model: {
                  value: _vm.AltroManutenzione,
                  callback: function ($$v) {
                    _vm.AltroManutenzione = $$v
                  },
                  expression: "AltroManutenzione",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _vm.AltroManutenzione
                ? _c("v-text-field", {
                    attrs: {
                      "hide-details": "",
                      readonly: "",
                      label: "Specificare:",
                    },
                    model: {
                      value: _vm.TestoAltroManutenzione,
                      callback: function ($$v) {
                        _vm.TestoAltroManutenzione = $$v
                      },
                      expression: "TestoAltroManutenzione",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }