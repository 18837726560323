var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    [
      _c(
        "v-stepper",
        { staticClass: "mt-12", attrs: { "non-linear": "", value: "1" } },
        [
          _c(
            "v-stepper-step",
            { attrs: { editable: "", complete: "", step: "1" } },
            [_vm._v("Intestazione")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "1" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c("pga-verbale-startup-header-create", {
                    model: {
                      value: _vm.PgaVerbaleStartupHeaderCreate,
                      callback: function ($$v) {
                        _vm.PgaVerbaleStartupHeaderCreate = $$v
                      },
                      expression: "PgaVerbaleStartupHeaderCreate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            { attrs: { editable: "", complete: "", step: "2" } },
            [_vm._v("Caratteristiche Generali")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: "2" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c(
                            "v-chip",
                            { staticClass: "ma-2", attrs: { label: "" } },
                            [_vm._v("Piattaforma Tecnologica di Riferimento")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("pga-verbale-startup-sub-header-create", {
                    model: {
                      value: _vm.PgaVerbaleStartupSubHeaderCreate,
                      callback: function ($$v) {
                        _vm.PgaVerbaleStartupSubHeaderCreate = $$v
                      },
                      expression: "PgaVerbaleStartupSubHeaderCreate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c(
                            "v-chip",
                            { staticClass: "ma-2", attrs: { label: "" } },
                            [_vm._v("Deliverables")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.PgaVerbaleStartupDeliverableCreate,
                          function (input, idx) {
                            return _c(
                              "pga-verbale-startup-deliverable-create",
                              {
                                key: idx,
                                attrs: { index: idx },
                                model: {
                                  value:
                                    _vm.PgaVerbaleStartupDeliverableCreate[idx],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.PgaVerbaleStartupDeliverableCreate,
                                      idx,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "PgaVerbaleStartupDeliverableCreate[idx]",
                                },
                              }
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            absolute: "",
                            dark: "",
                            fab: "",
                            bottom: "",
                            right: "",
                            color: "pink",
                          },
                          on: { click: _vm.addDeliverable },
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c(
                            "v-chip",
                            { staticClass: "ma-2", attrs: { label: "" } },
                            [_vm._v("Referenti Tecnici del Cliente")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.PgaVerbaleStartupReferenteClienteCreate,
                          function (input, idx) {
                            return _c(
                              "pga-verbale-startup-referente-cliente-create",
                              {
                                key: idx,
                                attrs: { index: idx },
                                model: {
                                  value:
                                    _vm.PgaVerbaleStartupReferenteClienteCreate[
                                      idx
                                    ],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.PgaVerbaleStartupReferenteClienteCreate,
                                      idx,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "PgaVerbaleStartupReferenteClienteCreate[idx]",
                                },
                              }
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            absolute: "",
                            dark: "",
                            fab: "",
                            bottom: "",
                            right: "",
                            color: "pink",
                          },
                          on: { click: _vm.addReferenteCliente },
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c(
                            "v-chip",
                            { staticClass: "ma-2", attrs: { label: "" } },
                            [_vm._v("Adempimenti del Team")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        [
                          _c(
                            "pga-verbale-startup-adempimenti-team-header-create",
                            {
                              attrs: {
                                "tipologia-verbale": _vm.LabelAdempimentiTeam,
                              },
                              model: {
                                value:
                                  _vm.PgaVerbaleStartupAdempimentiTeamHeaderCreate,
                                callback: function ($$v) {
                                  _vm.PgaVerbaleStartupAdempimentiTeamHeaderCreate =
                                    $$v
                                },
                                expression:
                                  "PgaVerbaleStartupAdempimentiTeamHeaderCreate",
                              },
                            }
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.PgaVerbaleStartupAdempimentiTeamConsultantCreate,
                          function (input, idx) {
                            return _c(
                              "pga-verbale-startup-adempimenti-team-consultant-create",
                              {
                                key: idx,
                                attrs: { index: idx },
                                model: {
                                  value:
                                    _vm
                                      .PgaVerbaleStartupAdempimentiTeamConsultantCreate[
                                      idx
                                    ],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.PgaVerbaleStartupAdempimentiTeamConsultantCreate,
                                      idx,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "PgaVerbaleStartupAdempimentiTeamConsultantCreate[idx]",
                                },
                              }
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            absolute: "",
                            dark: "",
                            fab: "",
                            bottom: "",
                            right: "",
                            color: "pink",
                          },
                          on: { click: _vm.addAdempimentiTeamConsultant },
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c(
                            "v-chip",
                            { staticClass: "ma-2", attrs: { label: "" } },
                            [_vm._v("Modalità e periodicità di Reporting")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("pga-verbale-startup-modalita-reporting-create", {
                    model: {
                      value: _vm.PgaVerbaleStartupModalitaReportingCreate,
                      callback: function ($$v) {
                        _vm.PgaVerbaleStartupModalitaReportingCreate = $$v
                      },
                      expression: "PgaVerbaleStartupModalitaReportingCreate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.TipologiaVerbaleStepper == "Progetto Applicativo"
            ? _c(
                "v-stepper-step",
                { attrs: { editable: "", complete: "", step: "3" } },
                [_vm._v("Progetto Applicativo")]
              )
            : _vm._e(),
          _c(
            "v-stepper-content",
            { attrs: { step: "3" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c(
                            "v-chip",
                            { staticClass: "ma-2", attrs: { label: "" } },
                            [_vm._v("Elementi in Ingresso")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.PgaVerbaleStartupElementiIngressoCreate,
                          function (input, idx) {
                            return _c(
                              "pga-verbale-startup-elementi-ingresso-create",
                              {
                                key: idx,
                                attrs: { index: idx },
                                model: {
                                  value:
                                    _vm.PgaVerbaleStartupElementiIngressoCreate[
                                      idx
                                    ],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.PgaVerbaleStartupElementiIngressoCreate,
                                      idx,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "PgaVerbaleStartupElementiIngressoCreate[idx]",
                                },
                              }
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            absolute: "",
                            dark: "",
                            fab: "",
                            bottom: "",
                            right: "",
                            color: "pink",
                          },
                          on: { click: _vm.addElementiIngresso },
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c(
                            "v-chip",
                            { staticClass: "ma-2", attrs: { label: "" } },
                            [
                              _vm._v(
                                "La Governance del progetto prevede i seguenti adempimenti:"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "pga-verbale-startup-governance-progetto-applicativo-create",
                        {
                          model: {
                            value:
                              _vm.PgaVerbaleStartupGovernanceProgettoApplicativoCreate,
                            callback: function ($$v) {
                              _vm.PgaVerbaleStartupGovernanceProgettoApplicativoCreate =
                                $$v
                            },
                            expression:
                              "PgaVerbaleStartupGovernanceProgettoApplicativoCreate",
                          },
                        }
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c(
                            "v-chip",
                            { staticClass: "ma-2", attrs: { label: "" } },
                            [_vm._v("Altre informazioni Progetto Applicativo")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "pga-verbale-startup-altre-info-progetto-applicativo-create",
                        {
                          model: {
                            value:
                              _vm.PgaVerbaleStartupAltreInfoProgettoApplicativoCreate,
                            callback: function ($$v) {
                              _vm.PgaVerbaleStartupAltreInfoProgettoApplicativoCreate =
                                $$v
                            },
                            expression:
                              "PgaVerbaleStartupAltreInfoProgettoApplicativoCreate",
                          },
                        }
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.TipologiaVerbaleStepper == "Servizio di Manutenzione"
            ? _c(
                "v-stepper-step",
                { attrs: { editable: "", complete: "", step: "4" } },
                [_vm._v("Servizio di Manutenzione")]
              )
            : _vm._e(),
          _c(
            "v-stepper-content",
            { attrs: { step: "4" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "4" } },
                [
                  _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                    _vm._v(
                      "La Governance del progetto prevede i seguenti adempimenti:"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "pga-verbale-startup-governance-servizio-manutenzione-create",
                        {
                          model: {
                            value:
                              _vm.PgaVerbaleStartupGovernanceServizioManutenzioneCreate,
                            callback: function ($$v) {
                              _vm.PgaVerbaleStartupGovernanceServizioManutenzioneCreate =
                                $$v
                            },
                            expression:
                              "PgaVerbaleStartupGovernanceServizioManutenzioneCreate",
                          },
                        }
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "4" } },
                    [
                      _c(
                        "v-chip",
                        { staticClass: "ma-2", attrs: { label: "" } },
                        [_vm._v("Altre informazioni Servizio di Manutenzione")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "pga-verbale-startup-altre-info-servizio-manutenzione-create",
                        {
                          model: {
                            value:
                              _vm.PgaVerbaleStartupAltreInfoServizioManutenzioneCreate,
                            callback: function ($$v) {
                              _vm.PgaVerbaleStartupAltreInfoServizioManutenzioneCreate =
                                $$v
                            },
                            expression:
                              "PgaVerbaleStartupAltreInfoServizioManutenzioneCreate",
                          },
                        }
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.TipologiaVerbaleStepper == "Progetto Infrastruttura"
            ? _c(
                "v-stepper-step",
                { attrs: { editable: "", complete: "", step: "5" } },
                [_vm._v("Progetto Infrastruttura")]
              )
            : _vm._e(),
          _c(
            "v-stepper-content",
            { attrs: { step: "5" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c(
                            "v-chip",
                            { staticClass: "ma-2", attrs: { label: "" } },
                            [_vm._v("Elementi in Ingresso")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.PgaVerbaleStartupElementiIngressoCreate,
                          function (input, idx) {
                            return _c(
                              "pga-verbale-startup-elementi-ingresso-create",
                              {
                                key: idx,
                                attrs: { index: idx },
                                model: {
                                  value:
                                    _vm.PgaVerbaleStartupElementiIngressoCreate[
                                      idx
                                    ],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.PgaVerbaleStartupElementiIngressoCreate,
                                      idx,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "PgaVerbaleStartupElementiIngressoCreate[idx]",
                                },
                              }
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            absolute: "",
                            dark: "",
                            fab: "",
                            bottom: "",
                            right: "",
                            color: "pink",
                          },
                          on: { click: _vm.addElementiIngresso },
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "4" } },
                [
                  _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                    _vm._v(
                      "La Governance del progetto prevede i seguenti adempimenti:"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "pga-verbale-startup-governance-progetto-infrastruttura-create",
                        {
                          model: {
                            value:
                              _vm.PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate,
                            callback: function ($$v) {
                              _vm.PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate =
                                $$v
                            },
                            expression:
                              "PgaVerbaleStartupGovernanceProgettoInfrastrutturaCreate",
                          },
                        }
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6", md: "4" } },
                [
                  _c("v-chip", { staticClass: "ma-2", attrs: { label: "" } }, [
                    _vm._v("Altre informazioni Progetto Infrastruttura"),
                  ]),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "mb-12", attrs: { tile: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "pga-verbale-startup-altre-info-progetto-infrastruttura-create",
                        {
                          model: {
                            value:
                              _vm.PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreate,
                            callback: function ($$v) {
                              _vm.PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreate =
                                $$v
                            },
                            expression:
                              "PgaVerbaleStartupAltreInfoProgettoInfrastrutturaCreate",
                          },
                        }
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }