import { render, staticRenderFns } from "./PgaVerbaleStartupAltreInfoServizioManutenzioneCreate.vue?vue&type=template&id=e7685408&"
import script from "./PgaVerbaleStartupAltreInfoServizioManutenzioneCreate.vue?vue&type=script&lang=ts&"
export * from "./PgaVerbaleStartupAltreInfoServizioManutenzioneCreate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VContainer,VRadio,VRadioGroup,VRow,VSwitch,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/src/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e7685408')) {
      api.createRecord('e7685408', component.options)
    } else {
      api.reload('e7685408', component.options)
    }
    module.hot.accept("./PgaVerbaleStartupAltreInfoServizioManutenzioneCreate.vue?vue&type=template&id=e7685408&", function () {
      api.rerender('e7685408', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/feature/Offerte/VerbaleStartup/Components/PgaVerbaleStartupAltreInfoServizioManutenzioneCreate.vue"
export default component.exports