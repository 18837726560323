var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c(
                "v-radio-group",
                {
                  attrs: {
                    label:
                      "La commessa prevede le seguenti tipologie di attività:",
                  },
                  model: {
                    value: _vm.TipologiaManutenzione,
                    callback: function ($$v) {
                      _vm.TipologiaManutenzione = $$v
                    },
                    expression: "TipologiaManutenzione",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: {
                      label: "Manutenzione Correttiva",
                      value: "ManutenzioneCorrettiva",
                    },
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: "Manutenzione Evolutiva",
                      value: "ManutenzioneEvolutiva",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-switch", {
                attrs: {
                  label:
                    "Il PM provvederà a comunicare formalmente ad HD tutti gli oggetti da inserire nei processi di backup",
                },
                model: {
                  value: _vm.PMBackup,
                  callback: function ($$v) {
                    _vm.PMBackup = $$v
                  },
                  expression: "PMBackup",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-switch", {
                attrs: {
                  label:
                    "Il PM segnalerà  al diretto responsabile qualsiasi eventuale criticità riguardante il servizio, in particolare \n     per quanto concerne i non auspicabili disallineamenti rispetto alla pianificazione.",
                },
                model: {
                  value: _vm.PMCriticita,
                  callback: function ($$v) {
                    _vm.PMCriticita = $$v
                  },
                  expression: "PMCriticita",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-textarea", {
                attrs: { rows: "2", label: "Note" },
                model: {
                  value: _vm.NoteServiziManutenzione,
                  callback: function ($$v) {
                    _vm.NoteServiziManutenzione = $$v
                  },
                  expression: "NoteServiziManutenzione",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }