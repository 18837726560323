var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: { label: "Sviluppo", readonly: "", "hide-details": "" },
                model: {
                  value: _vm.piattaformaSviluppo,
                  callback: function ($$v) {
                    _vm.piattaformaSviluppo = $$v
                  },
                  expression: "piattaformaSviluppo",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _vm.piattaformaSviluppo
                ? _c("v-text-field", {
                    attrs: {
                      label: "specificare:",
                      readonly: "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.sviluppoTesto,
                      callback: function ($$v) {
                        _vm.sviluppoTesto = $$v
                      },
                      expression: "sviluppoTesto",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: { label: "Test", readonly: "", "hide-details": "" },
                model: {
                  value: _vm.piattaformaTest,
                  callback: function ($$v) {
                    _vm.piattaformaTest = $$v
                  },
                  expression: "piattaformaTest",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _vm.piattaformaTest
                ? _c("v-text-field", {
                    attrs: {
                      label: "specificare:",
                      readonly: "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.testTesto,
                      callback: function ($$v) {
                        _vm.testTesto = $$v
                      },
                      expression: "testTesto",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: { label: "Collaudo", readonly: "", "hide-details": "" },
                model: {
                  value: _vm.piattaformaCollaudo,
                  callback: function ($$v) {
                    _vm.piattaformaCollaudo = $$v
                  },
                  expression: "piattaformaCollaudo",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _vm.piattaformaCollaudo
                ? _c("v-text-field", {
                    attrs: {
                      label: "specificare:",
                      readonly: "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.collaudoTesto,
                      callback: function ($$v) {
                        _vm.collaudoTesto = $$v
                      },
                      expression: "collaudoTesto",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Produzione",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.piattaformaProduzione,
                  callback: function ($$v) {
                    _vm.piattaformaProduzione = $$v
                  },
                  expression: "piattaformaProduzione",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "3" } },
            [
              _vm.piattaformaProduzione
                ? _c("v-text-field", {
                    attrs: {
                      label: "specificare:",
                      readonly: "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.produzioneTesto,
                      callback: function ($$v) {
                        _vm.produzioneTesto = $$v
                      },
                      expression: "produzioneTesto",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-textarea", {
                attrs: {
                  label: "Informazioni Rilevanti",
                  readonly: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.informazioniRilevanti,
                  callback: function ($$v) {
                    _vm.informazioniRilevanti = $$v
                  },
                  expression: "informazioniRilevanti",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }