var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Il PM ha condiviso il Gantt di progetto con il Team.",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.PMGanttTeam,
                  callback: function ($$v) {
                    _vm.PMGanttTeam = $$v
                  },
                  expression: "PMGanttTeam",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label:
                    "È stata condivisa la Tabella di Mapping delle Competenze con il Team, che riporta gli obiettivi di\n     arricchimento del know how di ognuno. La Tabella sarà aggiornata con cadenza settimanale, rispettando\n     le regole definite in procedura.",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.TabellaMapping,
                  callback: function ($$v) {
                    _vm.TabellaMapping = $$v
                  },
                  expression: "TabellaMapping",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Gli Standard di progetto utilizzati sono:",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.Standard,
                  callback: function ($$v) {
                    _vm.Standard = $$v
                  },
                  expression: "Standard",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "6" } },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.checkGovernance(_vm.Standard),
                    expression: "checkGovernance(Standard)",
                  },
                ],
                attrs: {
                  label: "Nome Documento:",
                  "hide-details": "",
                  readonly: "",
                },
                model: {
                  value: _vm.AltroStandard,
                  callback: function ($$v) {
                    _vm.AltroStandard = $$v
                  },
                  expression: "AltroStandard",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-expansion-panels",
        {
          attrs: { multiple: "" },
          model: {
            value: _vm.panel,
            callback: function ($$v) {
              _vm.panel = $$v
            },
            expression: "panel",
          },
        },
        [
          _c(
            "v-expansion-panel",
            [
              _c(
                "v-expansion-panel-header",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "4" } },
                    [
                      _c(
                        "v-chip",
                        { staticClass: "ma-2", attrs: { label: "" } },
                        [
                          _vm._v(
                            "Come si evince dal Gantt, saranno prodotti i seguenti documenti:"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "hide-details": "",
                              readonly: "",
                              label: "Specifiche Funzionali - entro il: ",
                            },
                            model: {
                              value: _vm.SpecificheFunzionali,
                              callback: function ($$v) {
                                _vm.SpecificheFunzionali = $$v
                              },
                              expression: "SpecificheFunzionali",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "3" } },
                        [
                          _vm.SpecificheFunzionali
                            ? _c("v-text-field", {
                                attrs: {
                                  type: "date",
                                  "hide-details": "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.GiornateSpecificheFunzionali,
                                  callback: function ($$v) {
                                    _vm.GiornateSpecificheFunzionali = $$v
                                  },
                                  expression: "GiornateSpecificheFunzionali",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Specifiche Database - entro il: ",
                              "hide-details": "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.SpecificheDatabase,
                              callback: function ($$v) {
                                _vm.SpecificheDatabase = $$v
                              },
                              expression: "SpecificheDatabase",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "3" } },
                        [
                          _vm.SpecificheDatabase
                            ? _c("v-text-field", {
                                attrs: {
                                  type: "date",
                                  "hide-details": "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.GiornateSpecificheDatabase,
                                  callback: function ($$v) {
                                    _vm.GiornateSpecificheDatabase = $$v
                                  },
                                  expression: "GiornateSpecificheDatabase",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Specifiche Tecniche - entro il: ",
                              "hide-details": "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.SpecificheTecniche,
                              callback: function ($$v) {
                                _vm.SpecificheTecniche = $$v
                              },
                              expression: "SpecificheTecniche",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "3" } },
                        [
                          _vm.SpecificheTecniche
                            ? _c("v-text-field", {
                                attrs: {
                                  type: "date",
                                  "hide-details": "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.GiornateSpecificheTecniche,
                                  callback: function ($$v) {
                                    _vm.GiornateSpecificheTecniche = $$v
                                  },
                                  expression: "GiornateSpecificheTecniche",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Documento UAT/Collaudo - entro il: ",
                              "hide-details": "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.DocumentoUAT,
                              callback: function ($$v) {
                                _vm.DocumentoUAT = $$v
                              },
                              expression: "DocumentoUAT",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "3" } },
                        [
                          _vm.DocumentoUAT
                            ? _c("v-text-field", {
                                attrs: {
                                  type: "date",
                                  "hide-details": "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.GiornateDocumentoUAT,
                                  callback: function ($$v) {
                                    _vm.GiornateDocumentoUAT = $$v
                                  },
                                  expression: "GiornateDocumentoUAT",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "1" } },
                        [
                          _c("v-switch", {
                            attrs: { label: "Altro:" },
                            model: {
                              value: _vm.AltroDocumentiGantt,
                              callback: function ($$v) {
                                _vm.AltroDocumentiGantt = $$v
                              },
                              expression: "AltroDocumentiGantt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "3" } },
                        [
                          _vm.AltroDocumentiGantt
                            ? _c("v-text-field", {
                                attrs: { "hide-details": "", readonly: "" },
                                model: {
                                  value: _vm.TestoAltroDocumentiGantt,
                                  callback: function ($$v) {
                                    _vm.TestoAltroDocumentiGantt = $$v
                                  },
                                  expression: "TestoAltroDocumentiGantt",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }